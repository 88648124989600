<template>
  <div class="content">
    <div>
      <div class="row">
        <div class="col">
          <div class="table-container">
            <table class="table">
              <thead class="head">
                <tr>
                  <th class="ps-4">Id</th>
                  <th>Origin</th>
                  <th>Entity</th>
                  <th>Action</th>
                  <th>Time</th>
                  <th>Payload</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="log in logsList.data" :key="log.id">
                  <td class="ps-4">{{log.id}}</td>
                  <td>{{log.origin}}</td>
                  <td>{{log.entity}}</td>
                  <td>{{log.action}}</td>
                  <td>{{formatValue(log.created_at, 'longDate')}} ({{formatValue(log.created_at, 'diffForHumans')}})</td>
                  <td>
                    <div class="vave-btn btn-small btn-blue ms-2" @click="showOutput(log.id), showOutputModal()" title="show job output">
                      <i class="fa fa-eye"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <pagination class="paginator" :limit="4" align="center" :data="logsList" @pagination-change-page="getLogs"></pagination>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="background p-4">
            <apexchart v-if="chartReady" type="line" height="350" :options="chartOptions" :series="series"></apexchart>
            <div v-else class="loading-placeholder">
              <div class="spinner-border spinner-border-sm ms-2" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <widget-config-modal ref="outputModal">
      <template v-slot:title>Output</template>
      <template v-slot:body>
        <div style="border:1px solid black;padding: 20px;">
          <div style="overflow: auto;">
            <span style="white-space: pre;">{{output}}</span>
          </div>
        </div>
      </template>
    </widget-config-modal>
  </div>
</template>

<script>
import axios from "../http.js";
import pagination from "laravel-vue-pagination";
import WidgetConfigModal from "./widgets/WidgetConfigModal";
import { formatValue } from "./../formatters";
import { forEach } from "lodash";
import VueApexCharts from "vue-apexcharts";

export default {
  data() {
    return {
      chartReady: false,
      output: "",
      logsList: {},
      series: [
        {
          name: "",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#77B6EA", "#545454"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Webhook Logs",
          align: "left",
        },

        markers: {
          size: 1,
        },
        xaxis: {
          categories: [],
          title: {
            text: "Day",
          },
        },
        yaxis: {
          title: {
            text: "# of Webhooks",
          },
          min: 0,
          max: 10,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    };
  },
  components: { WidgetConfigModal, pagination, apexchart: VueApexCharts },
  mounted() {
    if (Object.keys(this.logsList).length <= 0) {
      this.getLogs();
      this.getChartData();
    }
  },
  methods: {
    formatValue,
    showOutputModal() {
      this.$refs.outputModal.show();
    },
    showOutput(id) {
      this.output = "";
      axios.get("/api/admin/logs/webhooks/" + id).then((res) => {
        console.log(res.data);
        if (res.data && res.data.data) {
          this.output = res.data.data.payload;
        }
      });
    },
    getLogs(page = 1, perPage = 10) {
      this.output = "";
      this.logsList = {};
      axios
        .get("/api/admin/logs/webhooks?page=" + page + "&per_page=" + perPage)
        .then((response) => {
          if (response.data.data) {
            this.logsList = response.data;
          }
        });
    },
    getChartData() {
      axios.get("/api/admin/logs/webhooks/chart").then((response) => {
        if (response.data) {
          var origins = response.data.origins;
          var dates = [];
          forEach(origins, (origin) => {
            window[origin] = [];
          });

          forEach(response.data.data, (element, key) => {
            dates.push(key);
            forEach(origins, (origin) => {
              window[origin].push(element[origin] ? element[origin] : 0);
            });
          });

          let values = [];
          forEach(origins, (origin) => {
            values.push({
              name: origin,
              data: window[origin].reverse(),
            });
          });

          this.chartOptions.xaxis.categories = dates.reverse();
          this.chartOptions.yaxis.max = response.data.max;
          this.series = values;
        }
        this.chartReady = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
}
tbody tr {
  &:hover {
    background: #fafafa;
  }
}
</style>